import React from 'react'
import Page from '../../components/Page'
import Questionaire from '../../components/ROICalculator/Questionare'
import {ROI_CALCULATOR_DATA} from '../../components/ROICalculator/ROI-data'
import {ROI_CALCULATOR_DATA_SPECIFIC} from '../../components/ROICalculator/ROI-dataSpecific'

const CalculatorTemplate = () => {
  let isStartRoot = false
  if (localStorage.getItem('conv_session')) {
    const getCookie = JSON.parse(localStorage.getItem('conv_session'))
    const startURL = getCookie.startUrl
    if (
      startURL.substring(startURL.length - 4) === '/roi' ||
      startURL.substring(startURL.length - 5) === 'users' ||
      startURL.substring(startURL.length - 6) === 'users/'
    ) {
      isStartRoot = true
    }
  }
  let page
  if (isStartRoot) {
    page = ROI_CALCULATOR_DATA.chooseApplication
  } else {
    page = ROI_CALCULATOR_DATA_SPECIFIC.chooseApplication
  }
  const options = page?.options

  return (
    <Page>
      <Questionaire page={page} options={options} />
    </Page>
  )
}

export default CalculatorTemplate
